const Header = () => {
  return (
    <>
      <footer className="bg-pure-greys-25 rounded-lg  dark:bg-gray-800">
        <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © 2024 Het Enterprise . All Rights Reserved.
          </span>
        </div>
      </footer>
    </>
  );
};
export default Header;
